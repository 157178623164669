.banners {
    margin-top: 4rem;
    h4 {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
    }
    .item {
      margin-bottom: 1rem;
      img {
        max-width: 100%;
      }
      .delete-banner {
        width: max-content;
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        cursor: pointer;
        i {
          background: white;
          border-radius: 100%;
          padding: 2px;
          font-size: 22px;
        }
      }
      .edit-banner {
        width: max-content;
        position: absolute;
        top: 0.5rem;
        right: 3rem;
        cursor: pointer;
        i {
          background: white;
          border-radius: 100%;
          padding: 2px;
          font-size: 22px;
        }
      }
      .position-banner {
        width: max-content;
        position: absolute;
        top: 0.5rem;
        left: 1rem;
        background: black;
        color: #fff;
        border-radius: 10px;
        padding: 0rem 0.5rem;
      }
      .remate-name {
        background: white;
        width: 100%;
        padding: 5px;
        text-align: center;
        span {
          font-size: 12px;
        }
      }
    }
  }
  