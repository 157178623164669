.react-confirm-alert-overlay {
    background: rgba(255, 255, 255, 0.1) !important;
}

.react-confirm-alert-body {
    button:first-child {
        background: #c4c4c4;
        color: #000;
    }
}
