#deliver-list {
  .badge {
    margin-right: 10px;
  }

  button {
    padding: 2px 6px;
    border: transparent;
    margin-right: 10px;
    &.delete-delivery {
      background-color: hsl(4, 90%, 58%);
    }
  }
}
