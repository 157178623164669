@import "~react-image-gallery/styles/scss/image-gallery.scss";

.twitter-bs-wizard-tab-content {
    padding-top: 0 !important;
}

.margin-left-btn-add {
    margin-left: 5px !important;
}

.rdtPicker {
    position: fixed !important;
}