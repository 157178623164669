.step-number,
.step-title,
.nav-link {
    cursor: default;
}

.next {
    .button {
        background-color: #5664d2 !important;
    }
}

.add-image {
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    cursor: pointer;
    i {
        background: white;
        border-radius: 100%;
        padding: 0rem 0.3rem;
        font-size: 18px;
        border: 1px solid black;
    }
}

.delete-banner {
    width: max-content;
    position: absolute;
    top: -0.1rem;
    right: 0.5rem;
    cursor: pointer;
    i {
        background: white;
        border-radius: 100%;
        padding: 2px;
        font-size: 22px;
    }
}
